<div
  class="position-relative w-100"
  [class.is-mobile]="isMobile"
  [class.toast-visible]="timeToExpiration$ | async"
>
  <ds-img class="logo" [width]="124" path="../assets/images/IDHub-logo.png"></ds-img>
  <!--  <bz-language></bz-language>-->
</div>

<router-outlet></router-outlet>

@if (timeToExpiration$ | async; as seconds) {
  <ds-toast
    class="session-warning"
    [class.is-mobile]="isMobile"
    [type]="toastType.error"
    [icon]="toastIcon.error"
  >
    <span [innerHTML]="expireWarningKey | translate: { time: seconds | duration }"></span>
  </ds-toast>
}

<ds-img class="bz-certum-logo" path="../assets/images/certum-by-asseco.svg"></ds-img>
