import { Routes } from '@angular/router';
import { ROUTING } from './core/constants/routing.constants';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./wall/wall.module').then((m) => m.WallModule),
  },
  {
    path: ROUTING.PHONE_NUMBER,
    loadChildren: () =>
      import('./phone-number/phone-number.module').then((m) => m.PhoneNumberModule),
  },
  {
    path: ROUTING.EDO_APP_EID,
    loadChildren: () => import('./edo-app/edo-app.module').then((m) => m.EdoAppModule),
  },
  {
    path: ROUTING.EDO_APP_VERIFICATION,
    loadChildren: () => import('./edo-app/edo-app.module').then((m) => m.EdoAppModule),
  },
  {
    path: ROUTING.M_OBYWATEL,
    loadChildren: () => import('./m-obywatel/m-obywatel.module').then((m) => m.MObywatelModule),
  },
  {
    path: ROUTING.SERVICE_UNAVAILABLE,
    loadChildren: () =>
      import('./service-unavailable/service-unavailable.component').then(
        (m) => m.ServiceUnavailableComponent
      ),
  },
];
